import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule),
        data: { state: 'home' }
    },
    {
        path: 'news',
        loadChildren: () => import('./pages/news/news.module').then(m => m.NewsModule),
        data: { state: 'news' }
    },
    {
        path: 'contact',
        loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactModule),
        data: { state: 'contact' }
    },
    {
        path: 'projects',
        loadChildren: () => import('./pages/projects/projects.module').then(m => m.ProjectsModule),
        data: { state: 'projects' }
    },
    {
        path: 'research',
        loadChildren: () => import('./pages/research/research.module').then(m => m.ResearchModule),
        data: { state: 'research' }
    },
    {
        path: 'services',
        loadChildren: () => import('./pages/services/services.module').then(m => m.ServicesModule),
        data: { state: 'services' }
    },
    {
        path: 'equipment',
        loadChildren: () => import('./pages/equipment/equipment.module').then(m => m.EquipmentModule),
        data: { state: 'equipment' }
      },
    {
        path: 'terms',
        loadChildren: () => import('./pages/terms/terms.module').then(m => m.TermsModule),
        data: { state: 'terms' }
    },
    {
        path: 'privacypolicy',
        loadChildren: () => import('./pages/privacypolicy/privacypolicy.module').then(m => m.PrivacypolicyModule),
        data: { state: 'privacypolicy' }
    },
    {
        path: 'erdf',
        loadChildren: () => import('./pages/erdf/erdf.module').then(m => m.ErdfModule),
        data: { state: 'erdf' }
    },
];

@NgModule({
    // its buggy, doing it manually
    // imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }
