<nav class="nav"
     [class.hide-nav]="hideNav"
     [class.affix]="showNavBackground">
    <div class="container">
        <div class="logo">
            <a routerLink="/" routerLinkActive="active">
                <img [class.brighten-logo]="showNavBackground"
                     src="/assets/images/comand-logo-blue.svg"
                     class="img-logo">
            </a>
        </div>
        <div class="mainList" [class.showList]="showMobileNav">
            <ul class="navlinks">
                <li>
                    <a routerLink="/services" routerLinkActive="active" (click)="hide()">Services</a>
                </li>
                <li class="dropdown" (click)="toggleProjectsDropdown()">
                    <a class="dropbtn" routerLinkActive="active">Expertise <i class="fas fa-chevron-down dropdown-arrow"></i></a>
                    <div id="projectsDropdown" class="dropdown-content" [class.show]="isProjectsDropdownOpen">
                        <a routerLink="/projects" routerLinkActive="active" (click)="hide()">Projects</a>
                        <a routerLink="/equipment" routerLinkActive="active" (click)="hide()">Equipment</a>
                    </div>
                </li>
                <li>
                    <a routerLink="/research" routerLinkActive="active" (click)="hide()">Research</a>
                </li>
                <li>
                    <a routerLink="/news" routerLinkActive="active" (click)="hide()">News</a>
                </li>
                <li>
                    <a routerLink="/erdf" routerLinkActive="active" (click)="hide()">ERDF</a>
                </li>
                <li>
                    <a routerLink="/contact" routerLinkActive="active" (click)="hide()">Contact</a>
                </li>
                <li class="social-space">
                    <a href="https://twitter.com/aitsri"
                       target="_blank"
                       (click)="hide()">
                        <i class="fab fa-twitter-square"></i>
                    </a>
                </li>
                <li>
                    <a href="https://www.linkedin.com/company/sri-comand-tg/"
                       target="_blank"
                       (click)="hide()">
                        <i class="fab fa-linkedin"></i>
                    </a>
                </li>
            </ul>
        </div>
        <div class="burger-menu">
            <span
                class="navTrigger"
                (click)="toggleNav()"
                [class.active]="showMobileNav"
                [class.affixNavTrigger]="showNavBackground">
                <i></i>
                <i></i>
                <i></i>
            </span>
        </div> <!-- burger-menu ends here -->
    </div>
</nav>