import { Component, OnInit, HostListener } from '@angular/core';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

    showNavBackground: boolean = false;
    showMobileNav: boolean = false;
    hideNav: boolean = false;
    isProjectsDropdownOpen: boolean = false;

    prevScrollPos: number = window.pageYOffset;
    curScrollPos: number = window.pageYOffset;

    constructor(
    ) { }

    ngOnInit(): void {
    }

    /**
     * Listener for scroll event
     */
    @HostListener('window:scroll', ['$event'])
    onWindowScroll() {
        let offset = window.pageYOffset;

        if (offset > 250) {
            this.showNavBackground = true;

            this.hideNavHandler(offset);
        }
        else {
            this.showNavBackground = false;
            this.hideNav = false;
        }
    }

    /**
     * Hide navbar when scrolling down.
     * Show navbar when scrolling up.
     * @param pageYOffset Current page Y offset
     */
    hideNavHandler(pageYOffset: number) {
        this.curScrollPos = pageYOffset;

        this.hideNav = this.prevScrollPos < this.curScrollPos;

        this.prevScrollPos = this.curScrollPos;
    }

    /**
     * Toggle mobile navigation
     */
    toggleNav() {
        this.showMobileNav = !this.showMobileNav;
        if (this.showMobileNav) {
            this.isProjectsDropdownOpen = false;
        }
    }

    /**
     * Hide mobile navigation
     */
    hide() {
        if (this.showMobileNav) {
            this.showMobileNav = false;
        }
    }

    /**
     * Toggle Expertise dropdown
     */
    toggleProjectsDropdown() { // Renamed to be more accurate
        this.isProjectsDropdownOpen = !this.isProjectsDropdownOpen;
    }

    /**
     * Close Expertise dropdown
     */
    closeDropdown() { // New function to close the dropdown
        this.isProjectsDropdownOpen = false;
    }
}